import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {AppService} from '../app.service';
import { ILicenseDetails, IProductPriority, IVendorAttachments, IVendorComment, IVendorContacts, IVendorInfo, IVendorLicense, IVendorNotes, IVendorProductsModel, IVendorStatus, IVendorsFilterOptions,IDiscountPlan,IVendorInsurance, IVendorMerge } from 'src/app/Shared/models/VendorsModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class VendorService {
  private baseURL: string;
  constructor(private http: HttpClient,
    private appService: AppService) {
    this.baseURL = environment.apiBaseUrl;
  }

  private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  selectedVendorIds = [];
  public dlSwipeString = "";
  public resetCheckBoxes: () => void;

  getMarketVendorSearchList(marketId): Observable<boolean> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorSearchList?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const formats = res.body as any;
          return formats;
        }),
        catchError(this.handleError)
      );
  }
  
  getVendorColumnDescriptions(): Observable<any[]> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorColumnDescription`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const formats = res.body as any;
          return formats;
        }),
        catchError(this.handleError)
      );
  }

  getVendorSearchList(filterOption: IVendorsFilterOptions): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/VendorSearchList`;
    return this.http.post<any>(url, filterOption, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  DeleteVendorById(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/DeleteVendorById?Id=${Id}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorById(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorInfoById?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorByMarketVenId(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorByMarketVenId?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorInfoBySearch(SearchParm: any): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/GetVendorInfoBySearch`;
    return this.http.post<any>(url, SearchParm, options)
     .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  getAllVendorWithBalanceDueForAutoPay(vendorAutopayRequest: any): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/GetAllVendorWithBalanceDueForAutoPay`;
    return this.http.post<any>(url, vendorAutopayRequest, options)
     .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  getMarketVendorSettings(id : number): Observable<boolean> {
    return this.http.get<any>(
        `${this.baseURL}/Vendor/GetMarketVendorSetting?id=` + id,
        { observe: 'response' })
        .pipe(
          map(res => {
            const rentalSettings = res as any;
            return rentalSettings;
          }),
          catchError(this.handleError)
        );
   }

  GetVendorDashboardInfoById(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorDashboardInfoById?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorCompanyInfoById(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorCompanyInfoById?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorProductsById(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorProducts?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorProductImagesById(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorProductImages?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorMarketStatus(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorMarketRentalStatus?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorNotes(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorNotes?Id=${Id}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorInsurance(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorInsurance?Id=${Id}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorContacts(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorContacts?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorlicense(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorlicense?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorAttachment(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorAttachment?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  SaveOrUpdateVendorNotes(VendorNotes: IVendorNotes[]): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/SaveOrUpdateVendorNotes`;
    return this.http.post<any>(url, VendorNotes, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  SaveOrUpdateVendorContacts(VendorContacts: IVendorContacts): Observable<any> {    
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/SaveOrUpdateVendorContacts`;
    return this.http.post<any>(url, VendorContacts, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  SaveOrUpdateVendorAttachment(VendorAttachments: IVendorAttachments): Observable<any> {        
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/SaveOrUpdateVendorAttachment`;
    return this.http.post<any>(url, VendorAttachments, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  SaveOrUpdateVendorLicense(VendorLicense: IVendorLicense[]): Observable<any> {    
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/SaveOrUpdateVendorLicense`;
    return this.http.post<any>(url, VendorLicense, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  DeleteVendorInsuranceById(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/DeleteVendorInsuranceById?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
  } 
  SaveOrUpdateVendorInsurance(VendorInsuranceList: IVendorInsurance[]): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/SaveOrUpdateVendorInsurance`;
    return this.http.post<any>(url, VendorInsuranceList, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }


  DeleteVendorNotesById(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/DeleteVendorNotesById?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }  

  DownloadImage(BlobImageURL): Observable<any> {    
      return this.http.get(
        `${this.baseURL}/Vendor/DownloadImage?imageUrl=${BlobImageURL}`,
        { responseType: 'text' }
      ).pipe(
        map(res => res as string),
        catchError(this.handleError)
      );
  } 

DeleteVendorContactsById(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/DeleteVendorContactsById?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  DeleteVendorLicenseById(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/DeleteVendorLicenseById?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  DeleteVendorAttachmentById(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/DeleteVendorAttachmentById?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorComment(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorComments?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  UpdateOrSaveVendorComment(VendorComment: IVendorComment): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/UpdateVendorComments`;
    return this.http.post<any>(url, VendorComment, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }
  UpdateVendorProductPriority(Vendorproduct: IProductPriority): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/UpdateVendorProductPriority`;
    return this.http.post<any>(url, Vendorproduct, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  DeleteVendorProductById(Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/DeleteVendorProduct?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  SaveVendorProduct(Vendorproduct: IVendorProductsModel): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/SaveVendorProduct`;
    return this.http.post<any>(url, Vendorproduct, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  UpdateVendor(Vendor: IVendorInfo): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/UpdateVendorInfo`;
    return this.http.post<any>(url, Vendor, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  SaveVendor(Vendor: IVendorInfo): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/AddVendor`;
    return this.http.post<any>(url, Vendor, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorTypes(): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/admin/VendorType/GetVendorTypes`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  getVendorTypesByMarket(marketId): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseURL}/admin/CustomModSetting/GetVendorTypesByMarket?marketId=${marketId}`);
  }

  UpdateVendorStatus(VendorStatus: IVendorStatus): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/UpdateVendorStatus`;
    return this.http.post<any>(url, VendorStatus, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }
  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;      
      return throwError(errMessage);
    }
    return throwError(error || 'Node.js server error');
  }

  GetLicenseDetails(LicenseDetails: ILicenseDetails[]): Observable<any> {
    
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetLicenseDetails`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetDefaultVendor(): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetDefaultVendorDetails`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  getVendorHistory(historyInput): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/GetVendorHistory`;
    return this.http.post<any>(url, historyInput, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
    }

  getVendorBookingList(vendorId): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/AccountReceipt/GetVendorBookingDetails?vendorId=${vendorId}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

    GetVendorByMarketVendorId(Id: number): Observable<any> {
      return this.http.get<any>(
        `${this.baseURL}/Vendor/GetVendorByMarketVendorId?Id=${Id}`,  
        { observe: 'response' })
        .pipe(
          map(res => {
            const result = res as any;
            return result;
          }),
          catchError(this.handleError)
        );
    }   

    GetDiscountPlan(vendorId): Observable<any> {
          return this.http.get<any>(
        `${this.baseURL}/Vendor/GetDiscountPlan?vendorId=${vendorId}`,
  
        { observe: 'response' })
        .pipe(
          map(res => {
            const result = res.body as any;
            return result;
          }),
          catchError(this.handleError)
        );
  }
    
    DeleteDiscountPlanById(Id: string): Observable<any> {
      return this.http.get<any>(
        `${this.baseURL}/Vendor/DeleteDiscountPlanById?Id=${Id}`,
  
        { observe: 'response' })
        .pipe(
          map(res => {
            const result = res.body as any;
            return result;
          }),
          catchError(this.handleError)
        );
  }

    SaveOrUpdateDiscountPlan(DiscountPlan: IDiscountPlan[]): Observable<any> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      const url = `${this.baseURL}/Vendor/SaveOrUpdateDiscountPlan`;
      return this.http.post<any>(url, DiscountPlan, options)
        .pipe(
          map(res => {
            const response = res as any[];
            return response;
          }),
          catchError(this.handleError)
        );
    }
  
    GetProformaInvoices(vendorId){
      return this.http.get<any>(
        `${this.baseURL}/ProformaInvoice/GetProformaInvoices?vendorId=${vendorId}`,
        { observe: 'response' })
        .pipe(
          map(res => {
            const result = res.body as any;
            return result;
          }),
          catchError(this.handleError)
        );
    }

    VendorMerge(vendorMerge : IVendorMerge): Observable<any> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      const url = `${this.baseURL}/Vendor/VendorMerge`;
      return this.http.post<any>(url, vendorMerge)
        .pipe(
          map(res => {
            const response = res as any[];
            return response;
          }),
          catchError(this.handleError)
        );
    }
    
  GetSecurityQuestion(): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/General/GetVendorLoginSecurityQuestion`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorLoginInfo(VendorId: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorLoginInfoBT?VendorId=` + VendorId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  SaveVendorLoginDetail(VendorLoginDetail: any): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/SaveVendorLoginDetails`;
    return this.http.post<any>(url, VendorLoginDetail, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  SaveMailVendorLoginDetails(VendorLoginDetail: any): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/SaveMailVendorLoginDetails`;
    return this.http.post<any>(url, VendorLoginDetail, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  MailVendorLoginDetails(VendorId: number): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/MailVendorLoginDetails?vendorId=` + VendorId;
    return this.http.post<any>(url, VendorId, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetStateAndCityByZipcode(zipcode: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetStateAndCityByZipcode?zipcode=${zipcode}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
}

  getVendorAutoPayInfo(vendorId: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorAutoPayInfo?VendorId=${vendorId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }
  
  saveVendorAutoPayInfo(vendorAutoPayInfo: any): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/SaveVendorAutoPayInfo`;
    return this.http.post<any>(url, vendorAutoPayInfo, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorByMarketVendorIdOrDLNo(Id: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorByMarketVendorIdOrDLNo?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  SaveVendorAgreement(Agreementinfo: any): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/SaveVendorAgreement`;
    return this.http.post<any>(url, Agreementinfo, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorAgreement(vendorId: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorAgreement?vendorId=${vendorId}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  getBookedAndReservedBoothVendorDetails(boothNumber): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetBookedAndReservedBoothVendorDetails?BoothNumber=${boothNumber}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorVehiclesDetails(vendorId: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorVehiclesDetailsByVendorId?vendorId=${vendorId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  SaveVendorVehicleDetails(vendorVehicleModel: any): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/SaveVendorVehicleDetails`;
    return this.http.post<any>(url, vendorVehicleModel, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  DeleteVendorVehicle(id: number): Observable<any> {
    const url = `${this.baseURL}/Vendor/DeleteVendorVehicle?id=${id}`;
    return this.http.delete<any>(url, { observe: 'response' })
      .pipe(
        map(res => res.body),
        catchError(this.handleError)
      );
  }
}
